import template from './lockersAndBusses.html';
import styling from './lockersAndBusses.less';

export default angular.module('eventix.shop.lockersAndBusses', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.lockersAndBusses', {
            url: '/lockersAndBusses?eventId',
            views: {
                'shop': {
                    controller: 'LockersAndBussesController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('LockersAndBussesController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, ShopAnalytics) {
        var vm = this;
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;

        // TODO: Change ticket filtering!!!!
        // filtered
        vm.filterdTickets = {};
        vm.tickets = _.each(order.tickets, function(tickets, eventId){
            vm.filterdTickets[eventId] = _.filter(tickets, function(ticket){
                    return ((ticket.name).includes('bus') || (ticket.name).includes('kluis') || (ticket.name).includes('locker')) && !(ticket.name).includes('pendelbus') && !(ticket.name).includes('parkeren');
                    // return ticket;
                });
            });

        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.co2', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.co2', { shopId: shop.guid, eventId });
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            // if (_.flatten(_.values(order.reservations)).length < 1)
            //     UIMessages.push('common.shop.order.empty');
            // else
                $state.go('eventix.shop.other', { shopId: shop.guid });
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };
    }).name;
